<template>
  <div v-if="permissions.scope === 'read:homeowner'">
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center">
        <v-col cols="8">
            <base-material-card
                icon="mdi-currency-usd"
                title="Page-2 Breakdown"
                class="px-5 py-3"
            >

              <v-row>
                <v-col></v-col>
                <v-col cols="12">
                  <v-row>
                    <p class="subtitle-2 text-left">Page 1: {{forMatterPageOneCost}}</p>
                  </v-row>
                  <v-row>
                    <p class="subtitle-2 text-left">Page 2: {{forMatterPageTwoCost}}</p>
                  </v-row>
                  <v-row>
                    <p class="subtitle-2 text-left">Total Cost: {{theTotalCost}}</p>
                  </v-row>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="cost_breakdown"
                disable-pagination
                :hide-default-footer="true"
                >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <v-card>
                          <v-card-title>
                            <span class="card-title font-weight-light">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Item"
                                      :rules="itemRules"
                                      label="Item Description"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Cost"
                                      :rules="costRules"
                                      label="Item Cost"
                                      type="number"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title >Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.ModifiedDate`]="{ item }">
                  <span>{{item.ModifiedDate}}</span>
                </template>
                <template v-slot:[`item.cost_breakdown`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="view_breakdown(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </base-material-card>
        </v-col>
    </v-row>
  </v-container>
  </div>
   <div v-else-if="permissions.scope === 'create:users,fullaccess:staff' || permissions.scope === 'read:agent,write:agent'">
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center">
        <v-col cols="8">
            <base-material-card
                icon="mdi-currency-usd"
                title="Page-2 Breakdown"
                class="px-5 py-3"
            >
              <v-row>
                <v-col></v-col>
                <v-col cols="12">
                  <v-row>
                    <p class="subtitle-2 text-left">Page 1: {{forMatterPageOneCost}}</p>
                  </v-row>
                  <v-row>
                    <p class="subtitle-2 text-left">Page 2: {{forMatterPageTwoCost}}</p>
                  </v-row>
                  <v-row>
                    <p class="subtitle-2 text-left">Total Cost: {{theTotalCost}}</p>
                  </v-row>
                </v-col>
              </v-row>

                <v-data-table
                :headers="StaffHeaders"
                :items="cost_breakdown"
                disable-pagination
                :hide-default-footer="true"
                >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Item
                        </v-btn>
                      </template>
                      <v-card>
                          <v-card-title>
                            <span class="card-title font-weight-light">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Item"
                                      :rules="itemRules"
                                      label="Item Description"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Cost"
                                      :rules="costRules"
                                      label="Item Cost"
                                      type="number"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title >Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.ModifiedDate`]="{ item }">
                  <span>{{item.ModifiedDate}}</span>
                </template>
                <template v-slot:[`item.cost_breakdown`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="view_breakdown(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </base-material-card>
                       <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="selected_user.OwnerOne"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="selected_user.OwnerOneDate"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="selected_user.OwnerTwo"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="selected_user.OwnerTwoDate"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="save_signature()">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>
        </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'HomeownerCostBreakdownPageTwo',
    data(){
      return {
        url:process.env.VUE_APP_URL,
        cost_total:'',
        page1_cost:0,
        page2_cost:0,
        forMatterPageOneCost: 0,
        forMatterPageTwoCost: 0,
        dialog: false,
        dialogDelete: false,
        editedIndex:  -1,
        homeId : localStorage.getItem("homeid"),
        pageNumber : 2,
        valid: false,
        theTotalCost: 0,
        itemRules: [
          v => !!v || 'Item is required',
          v => (v && v.length <= 255) || 'Max 255 characters.',
          ],
        costRules: [
          v => (v && v.length <= 12) || 'Max 12 digits.'
        ],
        headers: [
            {
            text: 'Item',
            sortable: false,
            class:"primary--text",
            value: 'Item',
            },
            {
            text: 'Cost',
            value: 'Cost',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Modified Date',
            value: 'ModifiedDate',
            sortable: false,
            class:"primary--text",
            },
        ],
        StaffHeaders: [
            {
            text: 'Item',
            sortable: false,
            class:"primary--text",
            value: 'Item',
            },
            {
            text: 'Cost',
            value: 'Cost',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Modified Date',
            value: 'ModifiedDate',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Edit',
            value: 'cost_breakdown',
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        selected_user: {
          OwnerOne: '',
          OwnerOneDate: '',
          OwnerTwo: '',
          OwnerTwoDate: ''
        },
       cost_breakdown: [],
        selected_cost_breakdown: {
            ItemId:0,
            HomeId: '',
            Item: '',
            Cost: '',
            ModifiedDate:''
        },
        default_cost_breakdown: {
            ItemId:0,
            HomeId: '',
            Item: '',
            Cost: '',
            ModifiedDate:''
        },
      }
    },
    computed: {
      formTitle(){
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created(){
      this.initialize()
      this.get_costbreakdown_signature()
    },
    methods: {
        initialize(){
          this.get_cost_breakdown();
          this.get_cost_breakdown_total();
        },
        get_cost_breakdown_total (){

          axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_TOTAL_URL,
          {
            params: {
              home_id: this.homeId,
            }
          }
          )
          .then(response => {
            const { data } = response
            this.cost_total = data
            this.page1_cost=this.total_cost_page_1();
            this.page2_cost=this.total_cost_page_2();
            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',

              // These options are needed to round to whole numbers if that's what you want.
              //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
              //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });
            let theTotalCostTEMP = this.page1_cost + this.page2_cost
            let formattedTotal = formatter.format(theTotalCostTEMP)
            this.forMatterPageOneCost = formatter.format(this.page1_cost)
            this.forMatterPageTwoCost = formatter.format(this.page2_cost)
            this.theTotalCost = formattedTotal
          })
          .catch(error => {
            console.log(error)
          })

        },
        total_cost_page_1 (){
          
            if(this.cost_total.length==0)
              return 0;
            else if (this.cost_total[0].PageNumber == 1)
              return this.cost_total[0].sum
            else
              return 0;
            
        },
        total_cost_page_2 (){
            if(this.cost_total.length==0)
              return 0;
            else if (this.cost_total[0].PageNumber == 2)
              return this.cost_total[0].sum
            else if (this.cost_total.length==2 && this.cost_total[1].PageNumber == 2)
              return this.cost_total[1].sum
            else
              return 0;
        },
        getTheTotalCost(pageOneCost, pageTwoCost){
        this.theTotalCost = pageOneCost += pageTwoCost;
        },
        get_cost_breakdown(){
        axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_URL,
        {
          params: {
            home_id: this.homeId,
            page_number: this.pageNumber
          }
        }
        )
        .then(response => {
          this.cost_breakdown = response.data
        })
        .catch(error => {
          console.log(error)
        })
        },
        view_breakdown (cost_breakdown) {
          this.editedIndex = this.cost_breakdown.indexOf(cost_breakdown)
          this.selected_cost_breakdown = Object.assign({}, cost_breakdown)
          this.dialog = true
        },
        close(){
          this.dialog = false
          this.$nextTick(() => {
            this.selected_cost_breakdown = Object.assign({}, this.default_cost_breakdown)
            this.editedIndex = -1
          })
        },
        get_costbreakdown_signature(){
            axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_SIGNATURE_URL,
            {
              params: {
                home_id: this.homeId,
                page_number: this.pageNumber
              }
            }
            )
              .then(response => {

                  if (response.data)
                  {
                      var result = response.data
                      this.selected_user = JSON.parse(result[0]['Signature'])
                  }
              })
              .catch(error => {
              console.log(error)
              })
        },
        save(){
          var body ={
              "item_id":this.selected_cost_breakdown.ItemId,
              "home_id":this.homeId,
              "page_number":this.pageNumber,
              "item_desc":this.selected_cost_breakdown.Item,
              "cost":this.selected_cost_breakdown.Cost
            }

          if (this.editedIndex > -1) {
            axios
            .post(process.env.VUE_APP_UPDATE_COSTBREAKDOWN_URL,body)
            .then(() => {
            })
            .catch(error => {
              console.log(error)
            })
            Object.assign(this.cost_breakdown[this.editedIndex], this.selected_cost_breakdown)
          } else {
            axios
            .post(process.env.VUE_APP_INSERT_COSTBREAKDOWN_URL,body)
            .then(() => {
              this.initialize()
            })
            .catch(error => {
              console.log(error)
            })
          }
          this.selected_cost_breakdown = Object.assign({}, this.default_cost_breakdown)
          this.editedIndex = -1
          this.initialize()
          this.close()
        },
        save_signature(){
            var body ={
                "home_id":this.homeId,
                "page_number":this.pageNumber,
                "signature" :JSON.stringify(this.selected_user)
              }

              axios
              .post(process.env.VUE_APP_INSERT_COSTBREAKDOWN_SIGNATURE_URL,body)
              .then(() => {
                  this.alert = true
              })
              .catch(error => {
              console.log(error)
              })
        },
        deleteItem (cost_breakdown) {
          this.editedIndex = this.cost_breakdown.indexOf(cost_breakdown)
          this.selected_cost_breakdown = Object.assign({}, cost_breakdown)
          this.dialogDelete = true
        },
        deleteItemConfirm(){
          var item = this.cost_breakdown[this.editedIndex]
          var body = {"item_id":item.ItemId}
          axios
          .post(process.env.VUE_APP_DELETE_COSTBREAKDOWN_URL,body)
          .then(() => {
            this.initialize()
          })
          .catch(error => {
            console.log(error)
          })
          // this.cost_breakdown.splice(this.editedIndex, 1)
          this.initialize()
          this.closeDelete()
        },
        closeDelete(){
          this.dialogDelete = false
          this.$nextTick(() => {
            this.selected_cost_breakdown = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
    },
}
</script>
<style lang="scss" scoped>
</style>